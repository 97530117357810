<template>
  <div class="mepromise">
    <van-nav-bar
      title="对自己的承诺"
      left-text=""
      left-arrow
      class="navbar"
      @click-left="goBack"
    />
    <div class="mepromise-ctx">
      <div class="ctximg">
        <img
          src="https://lesson.iapeap.com/images/EmotionalValue/chengnuo.png"
          alt=""
        />
      </div>
      <div class="ctxcontent">
        <div>
          这将是一段探索自己情绪的旅程，在开它之前，试着用一点时间，写一份给自己的承诺。
        </div>
        <div class="ctxcontent-button" @click="gotosignature">
          <button>开始完成承诺书</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { memberLog } from "../../api/login";

export default {
  data() {
    return {
      item: {},
      starttime: "", //  进入页面时间
      endtime: "", //  退出页面时间
      time: "", //   页面累积时间
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    gotosignature() {
      this.$router.push({
        path: "/setout/signature",
        query: { item: this.item },
      });
    },
  },
  mounted() {
    this.item = this.$route.query.item;
    console.log("拿到的列表信息", this.item);
    this.timer = setInterval(() => {
      this.time++;
      // console.log("页面累积浏览时间是：",this.time,"秒")
    }, 1000); // 每秒触发一次
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    this.starttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("进入页面的系统时间是：", this.starttime);
  },
  beforeDestroy() {
    clearInterval(this.timer); // 清除定时器
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    this.endttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("离开页面的系统时间是", this.endttime);

    //把参数传给后端接口，starttime、endttime、time
    let data = {
      startTime: this.starttime,
      endTime: this.endttime,
      userId: window.localStorage.getItem("userId"),
      pageName: "对自己的承诺",
      timeStamp: this.time,
    };
    memberLog(data).then((res) => {});
  },
};
</script>

<style lang="less" scoped>
.navbar {
  background-color: #ededed;
}
/deep/ .van-nav-bar .van-icon {
  color: #464646;
}
.mepromise {
  background-color: #f4fafe;
  min-height: 100vh;
}
.mepromise-ctx {
  padding: 60px 20px 30px 20px;
  box-sizing: border-box;
}
.ctximg {
  display: flex;
  justify-content: center;
  img {
    width: 101px;
    height: 74px;
  }
}
.ctxcontent {
  height: 293px;
  background: #fff;
  padding: 40px 25px;
  box-sizing: border-box;
  text-indent: 20px;
}
.ctxcontent-button {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  button {
    border: 0;
    width: 200px;
    height: 36px;
    background-color: #72acf4;
    border-radius: 100px;
    color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
  }
}
</style>
